.landing-page {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10rem;
}

.home-parent {
    display: grid;
    height: auto;
    margin-bottom: 4rem;
    gap: 3rem 0;
    grid-auto-flow: row;
    grid-template: repeat(3, 1fr) / 1fr;
    grid-template-areas: "introduction-1""introduction-2""introduction-3";
}

#introduction-1,
#introduction-2,
#introduction-3 {
    display: grid;
    justify-items: center;
}

#introduction-1 {
    grid-area: introduction-1;
}

#introduction-2 {
    grid-area: introduction-2;
}

#introduction-3 {
    grid-area: introduction-3;
}

#image1,
#image2,
#image3 {
    width: 100%;
    max-width: 390px;
}

.home-parent article {
    align-self: center;
}

@supports (padding:max(0px)) {
    .bottom-nav {
        padding-bottom: max(.25rem, env(safe-area-inset-bottom));
    }
}

/* for desktop */
@media only screen and (min-width: 769px) {
    #introduction-1,
    #introduction-2,
    #introduction-3 {
        display: grid;
        align-items: center;
        gap: 0 1rem;
        grid-auto-flow: row;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        justify-items: center;
    }

    #introduction-1 {
        grid-template-areas: "image1 text1";
    }

    #image1,
    #image2,
    #image3 {
        justify-self: center;
    }

    #image1 {
        grid-area: image1;
    }

    #text1 {
        grid-area: text1;
    }

    #introduction-2 {
        grid-template-areas: "text2 image2";
    }

    #image2 {
        grid-area: image2;
    }

    #text2 {
        grid-area: text2;
    }

    #introduction-3 {
        grid-template-areas: "image3 text3";
    }

    #image3 {
        grid-area: image3;
    }

    #text3 {
        grid-area: text3;
    }
}
