* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

.bottom-nav {
    position: fixed;
    bottom: 0;
    display: grid;
    width: 100vw;
    height: auto;
    align-items: stretch;
    padding: 4px 1rem;
    background-color: #3b3a3f;
    column-gap: .5rem;
    grid-template-columns: repeat(3, 1fr);
    justify-items: stretch;
    z-index: 1;
}

.bn-tab {
    display: flex;
    max-width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bn-a {
    text-decoration: none;
}

.bn-tab > p {
    padding: 0 auto;
    color: var(--primary-color-lightest);
    font-size: 12px;
    font-weight: 700;
}

.active {
    background-color: var(--primary-color-lightest);
    border-radius: 6px;
    color: var(--primary-color-darkest);
}

.active p {
    color: var(--primary-color-darkest);
}

@supports (padding: max(0px)) {
    /* Specify that our padding should be the default padding or the safe area inset, whichever is greater. */
    .bottom-nav {
        padding-bottom: max(.25rem, env(safe-area-inset-bottom));
    }
}

/* For desktop */
@media only screen and (min-width: 769px) {
    @supports (padding: max(0px)) {
        .bottom-nav {
            padding-left: max(.5rem, env(safe-area-inset-left));
        }
    }

    .bottom-nav {
        position: sticky;
        top: 0;
        display: flex;
        width: auto;
        height: 100vh;
        flex-direction: column;
        justify-content: space-between;
        padding-right: .5rem;
        gap: 1rem;
    }

    .bottom-nav > div {
        display: flex;
        flex-direction: column;
        gap: .5rem;
    }

    .bn-a {
        padding: .5rem 1rem;
        text-decoration: none;
    }

    .bn-tab {
        display: flex;
        max-width: 100%;
        height: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        gap: .5rem;
    }
}
