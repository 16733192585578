/* COLORS */
:root {
    /* PRIMARY COLORS */
    --primary-color-lightest: #beafd0;
    --primary-color-light: #7f619c;
    --primary-color: #482776;
    --primary-color-dark: #372557;
    --primary-color-darkest: #231c36;
    --footer: #262430;

    /* DESTRUCTIVE COLROS */
    --destructive-color: #ed8594;
    --destructive-color-hover: #a65261;
    --destructive-color-clicked: #914755;
    --destructive-gradient: linear-gradient(180deg, #cf6679 0%, #ba5c6d 100%);
    --destructive-gradient-hover: linear-gradient(180deg, #a65261 0%, #914755 100%);
    --destructive-gradient-clicked: linear-gradient(180deg, #a65261 0%, #914755 100%);

    /* BUTTON COLORS */
    --button: linear-gradient(180deg, #beafd0 0%, #ab9ebb 100%);
    --button-hover: linear-gradient(180deg, #7f619c 0%, #72578c 100%);

    /* ELEVEATION */
    --elevation1: #16141a;
    --elevation2: rgb(22 20 26 / .05);
    --elevation3: rgb(22 20 26 / .07);
    --elevation4: rgb(22 20 26 / .08);
    --elevation5: rgb(22 20 26 / .09);
    --elevation6: rgb(22 20 26 / .11);
    --elevation7: rgb(22 20 26 / .12);
    --elevation8: rgb(22 20 26 / .13);
    --elevation9: rgb(22 20 26 / .15);
    --elevation10: rgb(22 20 26 / .16);

    /* TEXT */
    --white-middle-emphasis: rgb(255 255 255 / .6);
    --white-high-emphasis: rgb(255 255 255 / .87);
    --white: #fff;
    --default-stroke: rgb(255 255 255 / .18);
    --black: #000;
    --text-disabled-white: rgb(255 255 255 / .47);
    --button-label: rgb(0 0 0 / .7);

    /* fungerer kanskje ikke på alle knapper tihi */
    --main-background: linear-gradient(180deg, var(--primary-color-darkest) 0%, var(--elevation1) 100%);
}
