@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-weight: 700;
    src:
        local("OpenSans-Bold"),
        local("Open Sans Bold"),
        url("../assets/OpenSans-Bold-subset.woff2");
}

@font-face {
    font-display: swap;
    font-family: "Open Sans";
    font-weight: 400;
    src:
        local("OpenSans-Regular"),
        local("Open Sans Regular"),
        local("Open Sans") url("../assets/OpenSans-Regular-subset.woff2");
}

/* Typography */
html {
    font-size: 16px;
    line-height: 21px;
}

body,
.article,
p {
    color: var(--white-high-emphasis);
    font: 1rem/1.3125rem Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
legend {
    margin-top: 1.3125rem;
    font-family: "Open Sans", Arial, sans-serif;
    text-transform: capitalize;
}

h1,
h2,
h3,
legend {
    font-weight: 700;
}

h2,
h3,
.large-label,
legend {
    line-height: 2.625rem;
}

h3,
h4,
h5,
legend {
    margin-bottom: 0;
}

h4,
h5 {
    font-size: 1rem;
    line-height: 1.3125rem;
}

h1 {
    font-size: clamp(2.2rem, .5rem + 10vw, 2.8125rem);
    line-height: 3.9375rem;
}

h2,
.large-label {
    margin-bottom: 1.3125rem;
    font-size: 2rem;
}

h3,
legend {
    font-size: 1.4375rem;
}

h5 {
    margin-top: 1.3125rem;
}

th {
    font: 700 1.125rem "Open Sans", Arial, sans-serif;
    text-transform: capitalize;
}

.white-middle-emphasis {
    color: var(--white-middle-emphasis);
}

.subtitle {
    color: var(--white-middle-emphasis);
    font: 700 1.125rem "Open Sans", Arial, sans-serif;
    text-transform: uppercase;
}

.breadcrumb-item {
    padding: 4px 16px;
    line-height: 1.125rem;
    text-decoration: none;
}

a,
.breadcrumb-item:hover {
    color: var(--primary-color-lightest);
    text-decoration: underline;
}

.button-label,
label {
    font-weight: 700;
    text-transform: capitalize;
}

label {
    display: block;
}
