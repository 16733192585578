input[type="file"] {
    display: none;
}

.required::before {
    color: var(--destructive-color);
    content: "* ";
}

form p {
    color: var(--white-high-emphasis);
}

form select {
    display: block;
    width: 100%;
}
