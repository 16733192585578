.main-manage article {
    margin: 2rem 0;
}

.main-manage article h2 {
    margin-bottom: 1rem;
}

.main-manage article h3 {
    margin-top: 0;
}

.main-manage article>div {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.studyplan-selection label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.studyplan-selection label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--white-high-emphasis);
}

/* On mouse-over, add a grey background color */
.studyplan-selection label:hover input~.checkmark {
    background-color: var(--white-middle-emphasis);
}

/* When the checkbox is checked, add a blue background */
.studyplan-selection label input:checked~.checkmark {
    background-color: var(--primary-color-light);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.studyplan-selection label input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.studyplan-selection label .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid var(--white-high-emphasis);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* for desktop */
@media only screen and (min-width: 769px) {
    .main-manage article>div {
        max-width: 38rem;
        flex-direction: unset;
        gap: 1rem;
    }
}