.popup-box {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgb(0 0 0 / .66);
}

.box {
    position: relative;
    overflow: auto;
    max-width: 23.5rem;
    height: auto;
    max-height: 70vh;
    padding: 20px;
    border: 1px solid #999;
    margin: 0 auto;
    margin-top: calc(100vh - 85vh - 20px);
    background: #16141a;
    border-radius: 6px;
}

.close-icon {
    position: fixed;
    top: calc(100vh - 85vh - 33px);
    right: calc(15% - 30px);
    width: 25px;
    height: 25px;
    border: 1px solid #999;
    background: var(--main-background);
    border-radius: 50%;
    content: "x";
    cursor: pointer;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
}

.center-text {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.popup-box legend {
    margin: unset;
}
