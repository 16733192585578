.button,
.custom-file-upload {
    display: flex;
    width: 100%;
    min-height: 48px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: .5rem 1rem;
    border: none;
    margin: .5rem 0;
    border-radius: 6px;
    color: var(--button-label);
    cursor: pointer;
    font: bold 1rem Arial, sans-serif;
    gap: 8px;
    text-transform: capitalize;
    user-select: none;
}

/*
* Shadows for all buttons
*/
.button:hover,
.custom-file-upload:hover {
    box-shadow: 0 6px 6px rgb(0 0 0 / .25);
}

.button:active,
.custom-file-upload:active {
    box-shadow: inset 0 4px 4px rgb(0 0 0 / .25);
    transform: translateY(1px);
}

/*
* BUTTONS PRIMARY
*/
.primary,
.fab,
.custom-file-upload {
    background: var(--button);
}

.primary:hover,
.fab:hover,
.custom-file-upload:hover {
    background: #7f619c;
    background: var(--button-hover);
    color: rgb(255 255 255 / .9);
}

.primary:active,
.fab:active,
.custom-file-upload:active {
    background: var(--primary-color);
    color: rgb(255 255 255 / .7);
}

/*
* BUTTONS SECONDARY
*/
.secondary {
    border: 3px solid var(--primary-color-lightest);
    background-color: transparent;
    color: var(--button);
}

/* Disse fargene bør endres imo. De er vanskelig å se */
.secondary:hover {
    border-color: var(--primary-color-light);
    color: var(--primary-color-light);
}

.secondary:active {
    border-color: var(--primary-color);
    color: var(--primary-color);
}

/*
* BUTTONS SECONDARY + destructive
*/
.secondary.destructive {
    border: 3px solid var(--destructive-color);
    color: var(--destructive-color);
}

/* Disse fargene bør endres imo. De er vanskelig å se */
.secondary.destructive:hover {
    border-color: var(--destructive-color-hover);
    color: var(--destructive-color-hover);
}

.secondary.destructive:active {
    border-color: var(--destructive-color-clicked);
    color: var(--destructive-color-clicked);
}

/*
* BUTTONS TEXT-ONLY
*/
.text-only {
    display: inline;
    width: auto;
    border: none;
    margin: 0;
    background: none;
    border-radius: none;
    color: var(--primary-color-lightest);
    cursor: pointer;
}

.text-only:hover {
    background: unset;
    text-decoration: underline;
}

.text-only.destructive {
    color: var(--destructive-color);
}

.text-only.destructive:hover {
    color: var(--destructive-color-hover);
}

.button:active {
    box-shadow: none;
    color: var(--primary-color-light);
}

/*
* BUTTONS FAB
*/
.fab {
    width: auto;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
}

/*
* BUTTONS SIZING
*/

.full-size {
    width: 100%;
}

.half-size {
    width: 49%;

    /* better for flex */
}

.no-size {
    width: unset;
}
