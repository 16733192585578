body {
    min-height: 100vh;
    background-image: var(--main-background);
    background-repeat: no-repeat;
}

main {
    max-width: 936px;
    min-height: 100vh;
    margin: 1rem;
}

header {
    margin-bottom: 2rem;
}

header h1 {
    line-break: normal;
}

.cards-grid-container {
    display: grid;
    gap: 1rem;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;

    /* https://stackoverflow.com/a/44490047/14447555 */
}

.cards-grid-container a {
    height: 100%;
}

.cards-grid-container .card {
    display: grid;
    place-items: center;
}

.option-list-flex-container {
    margin-top: 2rem;
}

.option-list-flex-container li div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.option-list-flex-container li {
    padding: 0 1rem;
    margin: 0 -1rem;
    background-color: rgb(255 255 255 / .07);
}

.option-list-flex-container li:nth-child(even) {
    background-color: rgb(255 255 255 / .05);
}

fieldset > form > * {
    margin-bottom: 1rem;
}

textarea,
input {
    overflow: auto;
    width: 100%;
    padding: .5rem;
    border: 1px solid rgb(255 255 255 / .25);
    background-color: var(--elevation1);
    border-radius: 6px;
    color: white;
    font-size: 1rem;
    font-weight: 400;
    resize: none;
}

textarea {
    height: 9rem;
}

.buttons-side-by-side {
    display: flex;
    width: 100%;
    align-items: stretch;
    gap: 1rem;
}

.danger {
    color: var(--destructive-color);
}

select {
    min-height: 2rem;
    margin: auto;
}

.custom-select {
    display: block;
    padding: .5rem .25rem;
    border: 1px solid rgb(0 0 0 / .25);
    margin: 0;
    margin-bottom: 1rem;
    background: #39373c;
    border-radius: 6px;
    color: var(--white-high-emphasis);
    font-size: 1rem;
}

caption {
    margin-bottom: 1rem;
    color: var(--white-high-emphasis);
}

/* For desktop */
@media only screen and (min-width: 769px) {
    @supports (padding: max(0px)) {
        main,
        footer {
            padding-right: max(1rem, env(safe-area-inset-right));
            padding: 0 max(1rem, env(safe-area-inset-right)) 0 1rem;
        }
    }

    /* For the home page, NOT cards */
    .grid-container {
        display: grid;
        grid-template-columns: auto minmax(0, 1fr);
    }

    /* for cards that will be 1 * x coloum */
    .cards-grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    main {
        margin: 1rem auto;
    }
}

@media (prefers-reduced-motion: reduce) {
    .loader {
        animation: spin 4s linear infinite !important;
    }

    .card.loading {
        animation: 6s shine linear infinite !important;
    }

    .card:hover {
        transform: scale(1.005) !important;
    }
}
