/* RESET */

* {
    padding: 0;
    margin: 0;
    font-size: 1em;
    outline: none;
    text-decoration: none;
}

code,
kbd,
samp,
pre,
tt,
var,
textarea,
input,
select,
isindex,
listing,
xmp,
plaintext {
    font: inherit, 1em;
    white-space: normal;
}

dfn,
i,
cite,
var,
address,
em {
    font-style: normal;
}

th,
b,
strong,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

a,
img,
a img,
iframe,
form,
fieldset,
abbr,
acronym,
object,
applet,
table {
    border: none;
}

table {
    border-spacing: 0;
}

caption,
th,
td,
center {
    text-align: left;
    vertical-align: center;
}

q {
    quotes: """";
}

ul,
ol,
dir,
menu {
    list-style: none;
}

sub,
sup {
    vertical-align: baseline;
}

a {
    color: inherit;
}

hr {
    display: none;
}

font {
    color: inherit !important;
    font: inherit !important;
}

marquee {
    overflow: inherit !important;
    -moz-binding: none;
}

blink {
    text-decoration: none;
}

nobr {
    white-space: normal;
}
