.card {
    display: grid;
    padding: 1rem;
    border: 1px solid rgb(255 255 255 / .18);
    background-color: rgb(255 255 255 / .05);
    border-radius: 6px;
    box-shadow: 0 4px 4px rgb(0 0 0 / .25);
    hyphens: auto;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none;
    transition: .3s;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    word-wrap: break-word;
}

.card p {
    color: var(--white-middle-emphasis);
}

.card h3 {
    margin: 0;
    color: var(--white-high-emphasis);
}

.card:hover {
    border: 1px solid rgb(255 255 255 / .33);
    background: rgb(255 255 255 / .07);
    box-shadow: 0 6px 6px rgb(0 0 0 / .25);
    transform: scale(1.02);
}

.card:active {
    border: 1px solid rgb(255 255 255 / .09);
    background: rgb(255 255 255 / .04);
    box-shadow: inset 0 4px 4px rgb(0 0 0 / .25);
    color: var(--white-middle-emphasis);
    transform: unset;
}

.card.individual {
    box-shadow: unset;
    transform: unset;
}

.card.individual:hover {
    border: 1px solid rgb(255 255 255 / .18);
    background-color: rgb(255 255 255 / .05);
    box-shadow: unset;
    transform: unset;
}

.disabled-link {
    pointer-events: none;
}

a[disabled] {
    pointer-events: none;
}

/* by Chris Sev https://codepen.io/chris__sev/pen/mdrzYQE */
.card.loading {
    height: 100px;
    border: 1px solid rgb(255 255 255 / .09);
    animation: 2s shine linear infinite;
    background: linear-gradient(110deg, #2e2740 8%, #464056 18%, #2e2740 33%);
    background-color: #231c36;
    background-size: 200% 100%;
    box-shadow: unset;
}

.card.individual sup {
    padding: 0;
    margin: 0;
    font-size: smaller;
    text-transform: lowercase;
    vertical-align: super;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
