.icon {
    overflow: hidden;
    width: 24px;
    height: 24px;
    fill: currentcolor;
    vertical-align: -.15em;
}

.icon.large {
    width: 48px;
    height: 48px;
}

.icon.medium {
    width: 36px;
    height: 36px;
}

.icon.small {
    width: 18px;
    height: 18px;
}
